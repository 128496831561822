import React, { useEffect } from "react";

const GumroadEmbed = () => {
  useEffect(() => {
    // Dynamically load the Gumroad embed script
    const script = document.createElement("script");
    script.src = "https://gumroad.com/js/gumroad-embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="gumroad-product-embed">
      <a href="https://techlattice.gumroad.com/l/ttptech" target="_blank" rel="noreferrer">
        Loading...
      </a>
    </div>
  );
};

export default GumroadEmbed;
