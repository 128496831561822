import React, { useEffect } from 'react';

const GumroadButton = () => {
  useEffect(() => {
    // Dynamically add the Gumroad script
    const script = document.createElement('script');
    script.src = 'https://gumroad.com/js/gumroad.js';
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <a
      className="gumroad-button"
      href="https://techlattice.gumroad.com/l/ttptech"
      target="_blank"
      rel="noreferrer"
      data-gumroad-overlay-checkout="true"
    >
      Buy on Gumroad
    </a>
  );
};

export default GumroadButton;
