/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";

import Header from "parts/Header";
import Footer from "parts/Footer";
import StatsBanner from "parts/StatsBanner";
import GumroadButton from "parts/GumRoadButton";
import GumroadEmbed from "parts/GumRoadEmbed";

export default class ForEliteEntrepreneurs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <div>
          <h1 className="text-6xl text-theme-blue font-bold leading-tight">
            Exclusive Digital Solutions for Elite Entrepreneurs
          </h1>
          <div className="flex flex-col justify-start space-y-4 pl-4 pr-4 lg:pl-8 lg:pr-8 lg:pb-12">
            <p className="font-light text-l text-black leading-relaxed mt-20">
              Our company offers premium digital solutions tailored for elite
              entrepreneurs. Whether you need a cutting-edge mobile app, a
              custom website, or the backend systems to run your business, we provide the
              tools to elevate your business.
            </p>
            <p className="font-light text-l text-black leading-relaxed">
              Take the next step in your entrepreneurial journey by purchasing
              our exclusive ebook:{" "}
              <strong>
                Digital Entrepreneurs: The Ultimate Guide to Building Your
                Online Empire
              </strong>
              .
            </p>
            <GumroadEmbed />
            <GumroadButton />

            <p className="font-light text-l text-black leading-relaxed">
              Purchase this ebook to gain insights on creating a strong digital
              presence, setting up payment systems, managing ads, and more! With
              actionable steps and expert advice, this is the ultimate resource
              for entrepreneurs who want to dominate the digital world.
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="../assets/images/Collaborative.png"
            alt="about_for_elite_entrepreneurs"
          />
        </div>

        <StatsBanner />
        <Footer />
      </>
    );
  }
}
